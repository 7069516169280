import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import DarkModeToggle from './DarkModeToggle';
import LanguageSelector from './Languages';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../css/misc.css'; 
import logo from '../images/logo.png';

const Navbar = ({ menuDisplay, language, languages, onLanguageChange, initialLanguageCode }) => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const darkModeClass = 'dark-mode';
    const navbarClass = 'navbar-dark-mode';
    if (document.body.classList.contains(darkModeClass)) {
      setIsDarkMode(true);
    } else {
      setIsDarkMode(false);
    }
    const observer = new MutationObserver(() => {
      if (document.body.classList.contains(darkModeClass)) {
        setIsDarkMode(true);
      } else {
        setIsDarkMode(false);
      }
    });
    observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });
    return () => observer.disconnect();
  }, []);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const handleLinkClick = () => {
    setIsNavCollapsed(true);
    setOpenDropdown(null);
  };

  const handleDropdownClick = (id) => {
    if (openDropdown === id) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(id);
    }
  };

  const filteredMenuDisplay = menuDisplay.filter(menu => menu.hasMenu && menu.languageCode === language);

  return (
    <header className="page-head">
      <div className="rd-navbar-wrap rd-navbar-minimal">
        <nav className="rd-navbar" data-layout="rd-navbar-fixed" data-sm-layout="rd-navbar-fixed" data-md-device-layout="rd-navbar-fixed" data-lg-layout="rd-navbar-static" data-lg-device-layout="rd-navbar-static" data-stick-up-clone="false" data-md-stick-up-offset="100px" data-lg-stick-up-offset="100px">
          <div className="container container-fluid">
            <div className="rd-navbar-inner">
              <div className="rd-navbar-panel">
                <button className="rd-navbar-toggle" data-rd-navbar-toggle=".rd-navbar-nav-wrap"><span></span></button>
                <NavLink className="rd-navbar-brand brand" to="/">
                  <div className="brand-logo">
                    <img src={logo} alt="Laroutine Cafe Logo" />
                  </div>
                </NavLink>
              </div>
              <div className="rd-navbar-nav-wrap">
                <ul className="rd-navbar-nav">
                  {filteredMenuDisplay.map((menu) => (
                    <li
                      key={menu.id}
                      className={`nav-item ${menu.subMenu ? 'dropdown' : ''} ${openDropdown === menu.id ? 'show' : ''}`}
                      onClick={menu.subMenu ? () => handleDropdownClick(menu.id) : handleLinkClick}
                    >
                      <NavLink
                        className={`nav-link ${menu.subMenu ? 'dropdown-toggle' : ''}`}
                        to={menu.pageName}
                        id={`navbarDropdownMenuLink-${menu.id}`}
                        role="button"
                        data-bs-toggle={menu.subMenu ? 'dropdown' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openDropdown === menu.id ? 'true' : 'false'}
                        onClick={menu.subMenu ? undefined : handleLinkClick}
                      >
                        {menu.menuName}
                      </NavLink>
                      {menu.subMenu && (
                        <ul className={`dropdown-menu ${openDropdown === menu.id ? 'show' : ''}`} aria-labelledby={`navbarDropdownMenuLink-${menu.id}`}>
                          {menu.subMenu.filter(subMenu => subMenu.hasLink).map((subMenu) => (
                            <li key={subMenu.id}>
                              <NavLink className="dropdown-item" to={subMenu.pageName} onClick={handleLinkClick}>
                                {subMenu.menuName}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
                {languages && (
                  <LanguageSelector
                    languages={languages}
                    onLanguageChange={onLanguageChange}
                    initialLanguageCode={initialLanguageCode}
                  />
                )}
                <DarkModeToggle />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
