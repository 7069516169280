import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownButton, ButtonGroup, Image } from 'react-bootstrap';
import '../css/misc.css'; 
const LanguageSelector = ({languages, onLanguageChange, initialLanguageCode}) => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    languages.find(language => language.code === initialLanguageCode) || languages[0]
  );

  useEffect(() => {
    // Trigger the initial language code change when the component loads
    onLanguageChange(selectedLanguage);
  }, []);

  const handleSelect = (language) => {
    setSelectedLanguage(language);
    if (onLanguageChange) {
      onLanguageChange(language);
    }
  };
  const dropdownStyles = {
   // color: '#FFFFFF', // Change to your desired color
    //backgroundColor: '#000000', // Change to your desired background co
     fontSize: '12px', // Change to your desired font size
  };

  const imageStyles = {
    width: '16px', // Change to your desired image width
    marginRight: '5px', // Change to your desired margin
  };

  return (
    <DropdownButton style={dropdownStyles}
      as={ButtonGroup}
      title={
        <span style={dropdownStyles}>
  <Image src={`/images/flags/${selectedLanguage.imgSrc}`} alt={selectedLanguage.name}  style={imageStyles}  />
  {selectedLanguage.name}
        </span>
      }
      id="language-selector"
      className={`language-selector top-right-corner`} 
    >
      {languages.map((language) => (
        <Dropdown.Item key={language.code} onClick={() => handleSelect(language)}>
          <Image src={`/images/flags/${language.imgSrc}`}  alt={language.name}  style={imageStyles}  />
          {language.name}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

export default LanguageSelector;
