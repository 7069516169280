import React, { useEffect, useState } from 'react';
import { API_CMS_URL,API_KEY } from './constants';
import { toast } from 'react-toastify';
const DynamicForm = () => {
    const [htmlContent, setHtmlContent] = useState('');
const formDataToLineSeparatedString = (formData) => {
    let str = '';
    formData.forEach((value, key) => {
        str += `${key}: ${value}\n`;
    });
    return str;
};
const formDataToJson = (formData) => {
    const object = {};
   // formData.forEach((value, key) => {
   //     object[key] = value;
   // });
    return object;
};

    useEffect(() => {
        const form = document.getElementById('contact-us-form');
        if (form) {
            form.addEventListener('submit', handleSubmit);
        }

        return () => {
            if (form) {
                form.removeEventListener('submit', handleSubmit);
            }
        };
    }, [htmlContent]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const apiurl = `${API_CMS_URL}post/${API_KEY}/contactus`;
        //console.log(apiurl);
        const formData = new FormData(e.target);
        const jsonData = formDataToJson(formData);
        jsonData.name = formData.get('firstname')+' '+formData.get('lastname');
        jsonData.email = formData.get('email');
        jsonData.message = formDataToLineSeparatedString(formData);
        jsonData.subject="Message to Confluence Ventures";
        jsonData.ipaddress = '127.0.0.1'; // Example value, replace with actual
        jsonData.client_key = API_KEY; // Replace with actual client key        
        //const formData = new FormData(e.target);
        jsonData.phone = API_KEY;
//console.log( JSON.stringify(jsonData));
// Get the button element by its ID
const button = document.getElementById('submit-btn');
        fetch(apiurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body:  JSON.stringify(jsonData)
        })
        .then(response => response.json())
        .then(data => {
            //console.log(data);
  // Update button text and disable it
            button.textContent = 'Sending...';
                   
            if (data.success) {
            toast.success(data.message);
            } else {
                toast.error(data.message);
            }
            button.textContent = data.message;
            button.disabled = true;     
            //setMessage('Your message has been sent!');
        })
        .catch(error => {
            toast.error('An error occurred. Please try again.');
            
            console.error('Error:', error);
        });
    };

    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </div>
    );
};

export default DynamicForm;
