// Define your API URLs and other constants here
//export const API_CMS_URL = 'https://yourapi.com/api';
//export const ANOTHER_CONSTANT = 'SomeOtherValue';
//export const API_KEY='laroutinecafe';
export const API_KEY='laroutinecafe';
export const NewsID=18;
export const BlogID=18;
export const VlogID=18;
//export const HomePageID=10;
export const ContactUsPage='/reach-us';
export const PrimaryLanguage ='';
export const AdditionalLanguage1 ='en';

//export const API_KEY='matrimonylk';
//const apicontact='matrimonylk';
//const apikey='';
//export const API_CMS_URL = 'https://cvcmsservice20231227071440.azurewebsites.net/api/' ;
export const API_CMS_URL = 'https://cvlaroutinecafe.azurewebsites.net/api/';
//export const API_CMS_URL = 'https://cvcventurescms.azurewebsites.net/api/';
//https://cvcventurescms.azurewebsites.net/

//const msubject='Confluence Ventures : Thank you';
//const mmessage='Thank you for contacting us.\r\n We will get back to you as soon as we could.\r\nConfluence Ventures (Pv) Ltd. \r\n\r\nsubmitted data:\r\n';
//const successmessage='We received your message, we will contact you as soon as we could.\r\nConfluence Ventures (Pv) Ltd.';
//const errormessage='Sorry, something went wrong try again later.\r\n';


