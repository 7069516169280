import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Navbar'; // Adjust the path as needed

const NavbarWrapper = ({ menuDisplay, languages }) => {
  const [languageCode, setLanguageCode] = useState(''); // Initial state is empty
  const navigate = useNavigate();

  const handleLanguageChange = (language) => {
    setLanguageCode(language.code);
    //const path=(language.code && language.code!== '') ? '/${language.code}' : '/';
    const path = (language.code && language.code !== '') ? `/${language.code}` : '/';

    navigate(path);
  };

  useEffect(() => {
    // Set initial language code when the component mounts
    if (!languageCode) {
      setLanguageCode(''); // Default language code if not set
    }
  }, [languageCode]);

  return (
    <div>
      <Navbar
        menuDisplay={menuDisplay}
        languages={languages}
        language={languageCode}
        onLanguageChange={handleLanguageChange}
        initialLanguageCode={languageCode}
      />
    </div>
  );
};

export default NavbarWrapper;
