import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import {API_CMS_URL,API_KEY,BlogID,HomePageID, NewsID, VlogID } from './lib/constants';
//import Page from './lib/updateMetaTags';
import NavbarWrapper from './lib/NavbarWrapper';
import generateRouteComponents from './lib/GenerateRouteComponents ';
import LoadCMSPage from './lib/LoadCMSPage';
import RotatingLoader from './lib/RotatingLoader'
import PageNotFound from './lib/PageNotFound'
import Footer from './lib/Footer'
const getPageName = (url) => {
  const segments = url.split('/');

  if (segments.length > 2) {
    const [root, sub] = segments.slice(1); // Extract the first and second segments
    if (root === 'blog' || root === 'news' || root === 'vlog') {
      return `/${root}-${sub}`;
    }
  }

  return segments.length > 1 ? `/${segments[segments.length - 1]}` : url;
};

function App() {
  const vhref=window.location.pathname;

  const [datamenu, setDatamenu] = useState(null);
  const [loading, setLoading] = useState(true);
  //console.clear();
console.log("main");
useEffect(() => {
    const fetchData = async () => {
      try {
        const apiurl=API_CMS_URL+'initial/'+API_KEY+getPageName(vhref);
        const response = await fetch(apiurl);
        console.log(apiurl);
        if (!response.ok) {
          throw new Error('Network response was not ok'); // Throw error if response is not OK
        }
        const jsonData = await response.json();
        
        if (!jsonData.success) {
          // Use toast.error() if jsonData indicates failure
          toast.error('Data fetch was unsuccessful: ' + (jsonData.message || 'Unknown Error'));
        }
		
	  setDatamenu(jsonData); // Update state with fetched data
    //setNavData(jsonData.navData);
    //setPageData(jsonData.pageData);  
    setLoading(false);  
    //console.log(jsonData);
	  // Use toast.success() to show a success message
	  //console.log('Data fetched successfully!');
        
      } catch (error) {
        console.error('Failed to fetch data:', error);
        // Use toast.error() to display an error message
        toast.error('Failed to fetch data: ' + error.message);
      }
    };

    fetchData();
  }, []); // Runs only once on component mount

  if (loading) {
    return <RotatingLoader />;
  }

  return (
    <Router>
      <ToastContainer />

      <div className="page text-center">      
      <NavbarWrapper menuDisplay={datamenu.menuDisplay} languages={datamenu.languages} />      
      <Routes>
        {generateRouteComponents(datamenu.menuDisplay,datamenu)}
        <Route key="-1" path="news/:slug" element={<LoadCMSPage pageId={NewsID} pageName="news" initialPageData={datamenu}  />} />
        <Route key="-2" path="blog/:slug" element={<LoadCMSPage pageId={BlogID}  pageName="blog" initialPageData={datamenu}  />} />
        <Route key="-3" path="vlog/:slug" element={<LoadCMSPage pageId={VlogID}  pageName="vlog" initialPageData={datamenu}  />} />
        <Route key="-10"  path="*" element={<PageNotFound page="test" />} /> {/* Catch-all route */} 
      </Routes>
      </div>
      <Footer/>
    </Router>
  );
}
export default App;
